import { Grid } from 'src/components/mui-components';
import classNames from 'classnames';
import { useGetReportWidgetAll } from 'src/apis/reportingWidgetsAPI';
import { AverageHourlyRateReportingWidget } from '../AverageHourlyRateReportingWidget';
import { InvoicedPerMonthReportingWidget } from '../InvoicedPerMonthReportingWidget';
import { OverdueInvoicesReportingWidget } from '../OverdueInvoicesReportingWidget';
import { PlannedAbsenceReportingWidget } from '../PlannedAbsenceReportingWidget';
import { ProjectEconomyHealthReportingWidget } from '../ProjectEconomyHealthReportingWidget';
import { ReportingWidgetControl } from './components/ReportingWidgetControl';
import { TimeTrackingDelayReportingWidget } from '../TimeTrackingDelayReportingWidget';
import { VacationCalculationReportingWidget } from '../VacationCalculationReportingWidget';
import { BillablePercentageWidget } from '../BillablePercentageWidget';
import { ExternalPercentageWidget } from '../ExternalPercentageWidget';
import {
  AddedValuePerMonthReportingWidget,
  AddedValueBarPerMonthReportingWidget,
  AddedValuePerMonthHoursOnlyReportingWidget,
} from '../AddedValuePerMonthReportingWidget';
import styles from './ReportingWidgetContainer.module.scss';

type WidgetColumns = {
  xs: number;
  md: number;
  lg: number;
};

// Default column configuration
const FULL_COLUMNS: WidgetColumns = { xs: 12, md: 12, lg: 12 };
const HALF_COLUMNS: WidgetColumns = { xs: 12, md: 6, lg: 6 };

const HALF_COLUMN_WIDGETS = new Set([
  'TimeTrackingDelay',
  'BillablePercentage',
  'ExternalPercentage',
]);

// Widget component mapping
const WIDGET_COMPONENTS: Record<string, React.ComponentType> = {
  HourKPIHoursOnly: AddedValuePerMonthHoursOnlyReportingWidget,
  HourKPI: AddedValuePerMonthReportingWidget,
  HourKPIWithBAR: AddedValueBarPerMonthReportingWidget,
  InvoicedPerMonth: InvoicedPerMonthReportingWidget,
  AverageHourlyRate: AverageHourlyRateReportingWidget,
  PlannedAbsence: PlannedAbsenceReportingWidget,
  ProjectEconomyHealth: ProjectEconomyHealthReportingWidget,
  OverdueInvoices: OverdueInvoicesReportingWidget,
  TimeTrackingDelay: TimeTrackingDelayReportingWidget,
  VacationCalculation: VacationCalculationReportingWidget,
  BillablePercentage: BillablePercentageWidget,
  ExternalPercentage: ExternalPercentageWidget,
};

export const ReportingWidgetContainer = () => {
  const { widgetList } = useGetReportWidgetAll();

  return (
    <div data-automation-id="ReportWidgetContainer" className={classNames(styles.widgetContainer)}>
      <Grid container>
        {widgetList?.map((widget) => {
          const Component = WIDGET_COMPONENTS[widget.type];
          if (!Component) return null;

          const cols = HALF_COLUMN_WIDGETS.has(widget.type) ? HALF_COLUMNS : FULL_COLUMNS;

          return (
            <ReportingWidgetControl key={widget.type} widgetTypeName={widget.type}>
              <Grid item {...cols}>
                <Component />
              </Grid>
            </ReportingWidgetControl>
          );
        })}
      </Grid>
    </div>
  );
};

export default ReportingWidgetContainer;
