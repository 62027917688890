import React, { useMemo, useEffect, useState } from 'react';
import { GridRowModel, GridRowOrderChangeParams, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { useTranslation } from 'react-i18next';
import { Box, CircularProgress } from '@mui/material';
import { Text } from 'src/components/ui-components';
import { DataGrid, ToastifyAlert } from 'src/components/mui-components';
import { useGetWidgets, usePutWidgetOrder } from 'src/apis/widgetsAPI';
import { toast } from 'react-toastify';
import elements from 'src/assets/styles/variables/colors/global/elements.module.scss';

function updateRowPosition(
  initialIndex: number,
  newIndex: number,
  rows: Array<GridRowModel>,
): Promise<GridRowModel[]> {
  return new Promise((resolve) => {
    setTimeout(() => {
      const rowsClone = [...rows];
      const row = rowsClone.splice(initialIndex, 1)[0];
      rowsClone.splice(newIndex, 0, row);
      resolve(rowsClone);
    }, Math.random() * 500 + 100);
  });
}

// Helper: Remove "Widget" prefix from widget name
function getWidgetTranslationBaseKey(widgetName: string): string {
  return widgetName.startsWith('Widget') ? widgetName.replace(/^Widget/, '') : widgetName;
}

const WidgetsList = () => {
  const { data: widgetsData, error, isLoading: isQueryLoading } = useGetWidgets();
  const { t: tWidgets } = useTranslation('reportingWidgets');
  const { t: tPageSettings } = useTranslation('pageSettings');
  const { t: tFrontPage } = useTranslation('frontpage');

  // State to hold grid rows and loading state for the reordering process
  const [rows, setRows] = useState<GridRowModel[]>([]);
  const [loading, setLoading] = useState(false);
  const { mutate: updateWidgetOrder } = usePutWidgetOrder();

  // Map the API data to grid rows when available
  useEffect(() => {
    if (widgetsData?.widgetList) {
      const apiRows = widgetsData.widgetList.map((widget: any) => ({
        id: widget.widgetID, // Mapping widgetID to id
        widgetName: widget.widgetName,
      }));
      setRows(apiRows);
    }
  }, [widgetsData, error]);

  const columns = useMemo(
    () => [
      {
        field: 'widgetName',
        headerName: tPageSettings('WidgetName'),
        flex: 1,
        renderCell: (params: GridRenderCellParams<GridRowModel, string>) => {
          // Handle the possibility that params.value may be undefined.
          const widgetName: string = params.value ?? '';
          const baseKey = getWidgetTranslationBaseKey(widgetName);
          return tWidgets(baseKey);
        },
      },
    ],
    [tWidgets, tPageSettings],
  );

  // Handle row reordering and API update
  const handleRowOrderChange = (params: GridRowOrderChangeParams) => {
    setLoading(true);
    // Call the API with widgetId and the new displayOrder (targetIndex)
    updateWidgetOrder(
      { widgetId: params.row.id, displayOrder: params.targetIndex },
      {
        onSuccess: async () => {
          const newRows = await updateRowPosition(params.oldIndex, params.targetIndex, rows);
          setRows(newRows);
          setLoading(false);
          toast.success(<ToastifyAlert description={tPageSettings('NewOrderSavedMessage')} />, {
            autoClose: 5000,
            closeButton: false,
          });
        },
        onError: () => {
          setLoading(false);
          toast.error(<ToastifyAlert description={tFrontPage('MilestonesErrorMessage')} />, {
            autoClose: 5000,
            closeButton: false,
          });
        },
      },
    );
  };
  if (isQueryLoading) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <CircularProgress />
      </Box>
    );
  }
  if (error) {
    return <Text>{tFrontPage('MilestonesErrorMessage')}</Text>;
  }
  return (
    <DataGrid
      rows={rows}
      columns={columns}
      rowReordering
      onRowOrderChange={handleRowOrderChange}
      loading={loading || isQueryLoading}
      hideFooter
      disableColumnMenu
      disableColumnSorting
      sx={{
        borderLeft: `1px solid ${elements.colorBorder}`,
        borderRight: `1px solid ${elements.colorBorder}`,
        '& .MuiDataGrid-rowReorderCellPlaceholder': {
          visibility: 'hidden',
        },
      }}
    />
  );
};

export { WidgetsList };
