const roleWidgets = {
  Properties: {
    Roles: [
      {
        '': 2,
        RoleName: '10. Projektmedarbejder',
      },
      {
        Id: 6,
        RoleName: '15. Projektleder (Begrænsede rettigheder)',
      },
      {
        Id: 7,
        RoleName: '20. Projektleder',
      },
      {
        Id: 249,
        RoleName: '20221103',
      },
      {
        Id: 8,
        RoleName: '25. Ressourcekoordinator',
      },
      {
        Id: 10,
        RoleName: '30. Lønbogholder',
      },
      {
        Id: 4,
        RoleName: '35. Kundemedarbejder',
      },
      {
        Id: 9,
        RoleName: '40. Debitorbogholder (fakturering)',
      },
      {
        Id: 11,
        RoleName: '50. Afdelingsleder',
      },
      {
        Id: 12,
        RoleName: '60. Direktør',
      },
      {
        Id: 5,
        RoleName: '70. Salgsansvarlig (TimeLog CRM)',
      },
      {
        Id: 3,
        RoleName: '75. Sælger (TimeLog CRM)',
      },
      {
        Id: 14,
        RoleName: '80. Supporter (Help Desk User)',
      },
      {
        Id: 15,
        RoleName: '85. Supportansvarlig (Help Desk User)',
      },
      {
        Id: 13,
        RoleName: '90. Koordinator',
      },
      {
        Id: 16,
        RoleName: '95. Systemadministrator',
      },
      {
        Id: 219,
        RoleName: 'All turn OFF',
      },
      {
        Id: 220,
        RoleName: 'All turn ON',
      },
      {
        Id: 134,
        RoleName: 'Konsulent',
      },
      {
        Id: 240,
        RoleName: 'super',
      },
      {
        Id: 183,
        RoleName: 'supergirl',
      },
      {
        Id: 177,
        RoleName: 'superman',
      },
      {
        Id: 132,
        RoleName: 'Testrolle',
      },
    ],
    Widgets: [
      {
        Id: 1157,
        WidgetName: 'WidgetAverageHourlyRate',
        RolePermissions: [],
      },
      {
        Id: 1161,
        WidgetName: 'WidgetBillablePercentage',
        RolePermissions: [2, 7, 9, 10, 13, 16, 132, 134, 177, 220, 240, 249],
      },
      {
        Id: 1162,
        WidgetName: 'WidgetExternalPercentage',
        RolePermissions: [134, 177, 220, 240, 249],
      },
      {
        Id: 1158,
        WidgetName: 'WidgetHourKPI',
        RolePermissions: [],
      },
      {
        Id: 1160,
        WidgetName: 'WidgetHourKPIHoursOnly',
        RolePermissions: [],
      },
      {
        Id: 1159,
        WidgetName: 'WidgetHourKPIWithBAR',
        RolePermissions: [],
      },
      {
        Id: 1152,
        WidgetName: 'WidgetInvoicedPerMonth',
        RolePermissions: [],
      },
      {
        Id: 1151,
        WidgetName: 'WidgetPlannedAbsence',
        RolePermissions: [],
      },
      {
        Id: 1154,
        WidgetName: 'WidgetProjectEconomyHealth',
        RolePermissions: [],
      },
      {
        Id: 1156,
        WidgetName: 'WidgetTimeTrackingDelay',
        RolePermissions: [2, 7, 9, 10, 13, 16, 132, 134, 177, 220, 240, 249],
      },
      {
        Id: 1150,
        WidgetName: 'WidgetVacationCalculation',
        RolePermissions: [2, 7, 9, 10, 13, 16, 132, 134, 177, 220, 240, 249],
      },
    ],
  },
  Links: [
    {
      Href: 'http://localhost/tlp/api/v2/role-widgets',
      Rel: 'self',
    },
  ],
};
export { roleWidgets };
