import React, { useEffect, useState, useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Box, CircularProgress, Checkbox, Paper } from '@mui/material';
import { Text } from 'src/components/ui-components';
import { DataGrid, Tooltip, ToastifyAlert } from 'src/components/mui-components';
import {
  GridColDef,
  GridRowParams,
  GridRenderCellParams,
  DataGridProProps,
  GRID_DETAIL_PANEL_TOGGLE_FIELD,
} from '@mui/x-data-grid-pro';
import { toast } from 'react-toastify';
import { useGetAddedValuePerMonthHoursOnlyReportingWidget } from 'src/apis/reportingWidgetsAPI';
import { useGetWidgetsRoles, usePutWidgetRole } from 'src/apis/widgetsAPI';
import elements from 'src/assets/styles/variables/colors/global/elements.module.scss';

// Data type definitions
interface IRole {
  id: number;
  roleName: string;
}

interface IWidget {
  id: number;
  widgetName: string;
  rolePermissions: number[];
}

// Helper: Remove "Widget" prefix from widget name
function getWidgetTranslationBaseKey(widgetName: string): string {
  return widgetName.startsWith('Widget') ? widgetName.replace(/^Widget/, '') : widgetName;
}

const WidgetsRoles: React.FC = () => {
  const [detailPanelWidth, setDetailPanelWidth] = useState(0);
  const { currencyAbb } = useGetAddedValuePerMonthHoursOnlyReportingWidget();
  const { data, isLoading, error } = useGetWidgetsRoles();
  const { t: tWidgets } = useTranslation('reportingWidgets');
  const { t: tFrontPage } = useTranslation('frontpage');
  const updateMutation = usePutWidgetRole();

  // Let detail panel auto-size
  const getDetailPanelHeight = useCallback<NonNullable<DataGridProProps['getDetailPanelHeight']>>(
    () => 'auto' as const,
    [],
  );

  // Manage widgetPermissions in local state
  const [widgetPermissions, setWidgetPermissions] = useState<Record<number, number[]>>({});

  // Example dynamic width calculation (if you need to subtract a sidebar)
  useEffect(() => {
    function updateWidth() {
      setDetailPanelWidth(window.innerWidth - 480);
    }
    window.addEventListener('resize', updateWidth);
    updateWidth();
    return () => window.removeEventListener('resize', updateWidth);
  }, []);

  // Initialize widgetPermissions after data is loaded
  useEffect(() => {
    if (data) {
      const { widgets = [] } = data;
      const initialPermissions: Record<number, number[]> = {};
      widgets.forEach((widget: IWidget) => {
        initialPermissions[widget.id] = widget.rolePermissions || [];
      });
      setWidgetPermissions(initialPermissions);
    }
  }, [data]);

  // Handle toggling a role's permission for a widget
  const handleCheckboxChange = (widgetId: number, roleId: number) => {
    const currentRoles = widgetPermissions[widgetId] || [];
    const isChecked = currentRoles.includes(roleId);
    const updatedRoles = isChecked
      ? currentRoles.filter((id) => id !== roleId)
      : [...currentRoles, roleId];

    setWidgetPermissions((prev) => ({
      ...prev,
      [widgetId]: updatedRoles,
    }));

    updateMutation.mutate(
      { widgetId: widgetId.toString(), roleId, enabled: !isChecked },
      {
        onSuccess: () => {
          toast.success(<ToastifyAlert description={tWidgets('ChangesSuccessfullySaved')} />, {
            autoClose: 5000,
            closeButton: false,
          });
        },
        onError: () => {
          setWidgetPermissions((prev) => ({
            ...prev,
            [widgetId]: currentRoles,
          }));

          toast.error(<ToastifyAlert description={tWidgets('FailedToSaveChanges')} />, {
            autoClose: 5000,
            closeButton: false,
          });
        },
      },
    );
  };

  // Loading/error states
  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <CircularProgress />
      </Box>
    );
  }
  if (error) {
    return <Text>{tFrontPage('MilestonesErrorMessage')}</Text>;
  }
  if (!data) {
    return null;
  }

  const { roles = [], widgets = [] } = data;

  // Build role columns
  const roleColumns: GridColDef[] = roles.map((role: IRole) => ({
    field: `role_${role.id}`,
    headerName: role.roleName,
    width: 160,
    sortable: false,
    align: 'center',
    headerAlign: 'center',
    renderCell: (params: GridRenderCellParams) => {
      const row = params.row as IWidget & { translatedWidgetName: string };
      const widgetId = row.id;
      const checked = widgetPermissions[widgetId]?.includes(role.id) || false;
      return (
        <Checkbox
          checked={checked}
          onChange={(event) => {
            event.stopPropagation();
            handleCheckboxChange(widgetId, role.id);
          }}
          onKeyDown={(event) => event.stopPropagation()}
          color="primary"
          inputProps={{ 'aria-labelledby': `${widgetId} ${role.id}` }}
        />
      );
    },
  }));

  // Main columns
  const columns: GridColDef[] = [
    {
      field: 'translatedWidgetName',
      headerName: tWidgets('WidgetRolesTableNameColumn'),
      width: 180,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => (
        <Tooltip title={params.value || ''}>
          <Box
            sx={{
              width: '180px',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              overflow: 'hidden',
              whiteSpace: 'normal',
              lineHeight: '1.3em',
              maxHeight: '3em',
              pr: 2,
            }}
          >
            {params.value}
          </Box>
        </Tooltip>
      ),
    },
    ...roleColumns,
  ];
  // Create rows
  const rows = widgets.map((widget: IWidget) => {
    const baseKey = getWidgetTranslationBaseKey(widget.widgetName);
    return {
      ...widget,
      id: widget.id,
      translatedWidgetName: tWidgets(baseKey),
    };
  });

  // Detail panel content
  const getDetailPanelContent = (params: GridRowParams<IWidget>) => {
    const { row } = params;
    const baseKey = getWidgetTranslationBaseKey(row.widgetName);
    const description = tWidgets(`${baseKey}WidgetDescription`);

    return (
      <Box
        sx={{
          height: '100%',
          width: `${detailPanelWidth}px !important`, // or remove if not needed
          overflowY: 'auto',
          borderTop: `1px solid ${elements.colorBorder}`,
          py: 2,
        }}
      >
        <Paper sx={{ mx: 'auto', width: '90%', p: 1 }}>
          <Trans
            i18nKey={description}
            components={{ 1: <br /> }}
            values={{ currency: currencyAbb?.toString() }}
          />
        </Paper>
      </Box>
    );
  };

  return (
    <Box sx={{ width: '100%' }}>
      <DataGrid
        // Pinned columns: using the constant for detail panel toggle
        // plus your "translatedWidgetName" column
        pinnedColumns={{ left: [GRID_DETAIL_PANEL_TOGGLE_FIELD, 'translatedWidgetName'] }}
        disableColumnMenu
        disableColumnTopBorder
        disableRowSelectionOnClick
        disableColumnResize
        autoHeight
        rows={rows}
        columns={columns}
        loading={false}
        getDetailPanelContent={getDetailPanelContent}
        getDetailPanelHeight={getDetailPanelHeight}
        pagination
        hideFooter
        pageSizeOptions={[20, 50, 100]}
        initialState={{
          density: 'standard',
          pagination: {
            paginationModel: {
              pageSize: 20,
            },
          },
        }}
        sx={{
          border: `1px solid ${elements.colorBorder}`,
          '& .MuiDataGrid-columnHeaders': {
            minHeight: 'auto',
            maxHeight: 'none',
            lineHeight: '1.3em',
            overflow: 'visible',
            wordWrap: 'break-word',
          },
          '& .MuiDataGrid-columnHeaderTitle': {
            whiteSpace: 'normal',
            display: 'block',
            textAlign: 'center',
            wordBreak: 'break-word',
            fontSize: '13px',
            paddingY: '5px',
          },
          '& .MuiDataGrid-columnHeader': {
            height: 'auto !important',
          },
        }}
      />
    </Box>
  );
};

export { WidgetsRoles };
