import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { SettingsContentWrapper } from 'src/components/layout/SettingsContentWrapper';
import { WidgetsList } from './Components';
import { PageSettingsWrapper } from '../../components/PageSettingsWrapper';

const FrontPageLocal = () => {
  const { t: tPageSettings } = useTranslation('pageSettings');
  return (
    <PageSettingsWrapper>
      <SettingsContentWrapper
        header={tPageSettings('FrontPageHeading')}
        description={tPageSettings('NoSettingsDescription')}
      />
      <SettingsContentWrapper
        header={tPageSettings('WidgetsHeading')}
        description={tPageSettings('WidgetsDescription')}
      >
        <WidgetsList />
      </SettingsContentWrapper>
    </PageSettingsWrapper>
  );
};

export default FrontPageLocal;
