function getRandomArbitrary(min: number, max: number): number {
  return parseFloat((Math.random() * (max - min) + min).toFixed(2));
}

const randomDecimal = (min: number, max: number, decimalPlaces: number): number => {
  const random = Math.random() * (max - min) + min;
  return parseFloat(random.toFixed(decimalPlaces));
};

const generateRows = [
  {
    Properties: {
      Id: 0,
      Date: '2024-09-19T00:00:00+01:00',
      ProjectId: 1,
      ProjectNo: `${Math.floor(Math.random() * 100000)}-${Math.floor(Math.random() * 100)}`,
      ProjectName: `P01.001 _TIM - Time & Material`,
      Wbs: randomDecimal(1, 5.5, 1),
      TaskId: 10,
      TaskName: `NPS time`,
      TaskNo: `T${Math.floor(Math.random() * 1000)}`,
      Comment: `Comment 1`,
      AdditionalText: 'My Hello World',
      EmployeeInitials: 'KLA',
      EmployeeName: 'Ang Kian Leong',
      RegisteredHours: getRandomArbitrary(0, 8),
      InvoiceId: 1,
      IsInvoiced: false,
      WorkItemGuid: 1,
      AccountingPeriod: 0,
      IsInClosedAccountingPeriod: false,
    },
  },
  {
    Properties: {
      Id: 1,
      Date: '2024-09-19T00:00:00+01:00',
      ProjectId: 2,
      ProjectNo: `${Math.floor(Math.random() * 100000)}-${Math.floor(Math.random() * 100)}`,
      ProjectName: `P02.003 _TIM - New Reporting`,
      Wbs: randomDecimal(1, 5.5, 1),
      TaskId: 16,
      TaskName: `» On-line support`,
      TaskNo: `T${Math.floor(Math.random() * 1000)}`,
      Comment: `Comment 2`,
      AdditionalText: '',
      EmployeeInitials: 'KLA',
      EmployeeName: 'Ang Kian Leong',
      RegisteredHours: getRandomArbitrary(0, 8),
      InvoiceId: 2,
      IsInvoiced: false,
      WorkItemGuid: 2,
      AccountingPeriod: 0,
      IsInClosedAccountingPeriod: false,
    },
  },
  {
    Properties: {
      Id: 2,
      Date: '2024-09-19T00:00:00+01:00',
      ProjectId: 3,
      ProjectNo: `${Math.floor(Math.random() * 100000)}-${Math.floor(Math.random() * 100)}`,
      ProjectName: `P05.006 _TIM - Implementing`,
      Wbs: randomDecimal(1, 5.5, 1),
      TaskId: 18,
      TaskName: `Internal meetings (O13001)`,
      TaskNo: `T${Math.floor(Math.random() * 1000)}`,
      Comment: `Comment 3`,
      AdditionalText: 'Hello Python',
      EmployeeInitials: 'KLA',
      EmployeeName: 'Ang Kian Leong',
      RegisteredHours: getRandomArbitrary(0, 8),
      InvoiceId: 3,
      IsInvoiced: true,
      WorkItemGuid: 3,
      AccountingPeriod: 0,
      IsInClosedAccountingPeriod: false,
    },
  },
  {
    Properties: {
      Id: 3,
      Date: '2024-09-19T00:00:00+01:00',
      ProjectId: 5,
      ProjectNo: `${Math.floor(Math.random() * 100000)}-${Math.floor(Math.random() * 100)}`,
      ProjectName: `P16.665 _TIM - Scripting`,
      Wbs: randomDecimal(1, 5.5, 1),
      TaskId: 18,
      TaskName: `Internal meetings (O13001)`,
      TaskNo: `T${Math.floor(Math.random() * 1000)}`,
      Comment: `Comment 4`,
      AdditionalText: '',
      EmployeeInitials: 'APD',
      EmployeeName: 'Anders',
      RegisteredHours: getRandomArbitrary(0, 8),
      InvoiceId: 4,
      IsInvoiced: false,
      WorkItemGuid: 4,
      AccountingPeriod: 1,
      IsInClosedAccountingPeriod: true,
    },
  },
  {
    Properties: {
      Id: 4,
      Date: '2024-09-19T00:00:00+01:00',
      ProjectId: 6,
      ProjectNo: `${Math.floor(Math.random() * 100000)}-${Math.floor(Math.random() * 100)}`,
      ProjectName: `P20.444 _TIM - Administration`,
      Wbs: randomDecimal(1, 5.5, 1),
      TaskId: 12,
      TaskName: `» Data Work`,
      TaskNo: `T${Math.floor(Math.random() * 1000)}`,
      Comment: `Comment 4`,
      AdditionalText: '',
      EmployeeInitials: 'APD',
      EmployeeName: 'Anders',
      RegisteredHours: getRandomArbitrary(0, 8),
      InvoiceId: 5,
      IsInvoiced: false,
      WorkItemGuid: 5,
      AccountingPeriod: 1,
      IsInClosedAccountingPeriod: true,
    },
  },
  {
    Properties: {
      Id: 5,
      Date: '2024-09-19T00:00:00+01:00',
      ProjectId: 5,
      ProjectNo: `${Math.floor(Math.random() * 100000)}-${Math.floor(Math.random() * 100)}`,
      ProjectName: `P01.001 _TIM - Time & Material`,
      Wbs: randomDecimal(1, 5.5, 1),
      TaskId: 10,
      TaskName: `NPS time`,
      TaskNo: `T${Math.floor(Math.random() * 1000)}`,
      Comment: `Comment 5`,
      AdditionalText: 'My Hello World',
      EmployeeInitials: 'KLA',
      EmployeeName: 'Ang Kian Leong',
      RegisteredHours: getRandomArbitrary(0, 8),
      InvoiceId: 5,
      IsInvoiced: false,
      WorkItemGuid: 5,
      AccountingPeriod: 1,
      IsInClosedAccountingPeriod: true,
    },
  },
  {
    Properties: {
      Id: 6,
      Date: '2024-09-19T00:00:00+01:00',
      ProjectId: 6,
      ProjectNo: `${Math.floor(Math.random() * 100000)}-${Math.floor(Math.random() * 100)}`,
      ProjectName: `P02.003 _TIM - New Reporting`,
      Wbs: randomDecimal(1, 5.5, 1),
      TaskId: 16,
      TaskName: `» On-line support`,
      TaskNo: `T${Math.floor(Math.random() * 1000)}`,
      Comment: `Comment 6`,
      AdditionalText: '',
      EmployeeInitials: 'KLA',
      EmployeeName: 'Ang Kian Leong',
      RegisteredHours: getRandomArbitrary(0, 8),
      InvoiceId: 6,
      IsInvoiced: false,
      WorkItemGuid: 6,
      AccountingPeriod: 1,
      IsInClosedAccountingPeriod: true,
    },
  },
  {
    Properties: {
      Id: 7,
      Date: '2024-09-19T00:00:00+01:00',
      ProjectId: 7,
      ProjectNo: `${Math.floor(Math.random() * 100000)}-${Math.floor(Math.random() * 100)}`,
      ProjectName: `P05.006 _TIM - Implementing`,
      Wbs: randomDecimal(1, 5.5, 1),
      TaskId: 18,
      TaskName: `Internal meetings (O13001)`,
      TaskNo: `T${Math.floor(Math.random() * 1000)}`,
      Comment: `Comment 7`,
      AdditionalText: 'Hello Python',
      EmployeeInitials: 'KLA',
      EmployeeName: 'Ang Kian Leong',
      RegisteredHours: getRandomArbitrary(0, 8),
      InvoiceId: 7,
      IsInvoiced: true,
      WorkItemGuid: 7,
      AccountingPeriod: 1,
      IsInClosedAccountingPeriod: true,
    },
  },
  {
    Properties: {
      Id: 8,
      Date: '2024-09-19T00:00:00+01:00',
      ProjectId: 8,
      ProjectNo: `${Math.floor(Math.random() * 100000)}-${Math.floor(Math.random() * 100)}`,
      ProjectName: `P16.665 _TIM - Scripting`,
      Wbs: randomDecimal(1, 5.5, 1),
      TaskId: 18,
      TaskName: `Internal meetings (O13001)`,
      TaskNo: `T${Math.floor(Math.random() * 1000)}`,
      Comment: `Comment 8`,
      AdditionalText: '',
      EmployeeInitials: 'APD',
      EmployeeName: 'Anders',
      RegisteredHours: getRandomArbitrary(0, 8),
      InvoiceId: 8,
      IsInvoiced: false,
      WorkItemGuid: 8,
      AccountingPeriod: 1,
      IsInClosedAccountingPeriod: true,
    },
  },
  {
    Properties: {
      Id: 9,
      Date: '2024-09-19T00:00:00+01:00',
      ProjectId: 9,
      ProjectNo: `${Math.floor(Math.random() * 100000)}-${Math.floor(Math.random() * 100)}`,
      ProjectName: `P20.444 _TIM - Administration`,
      Wbs: randomDecimal(1, 5.5, 1),
      TaskId: 12,
      TaskName: `» Data Work`,
      TaskNo: `T${Math.floor(Math.random() * 1000)}`,
      Comment: `Comment 9`,
      AdditionalText: '',
      EmployeeInitials: 'APD',
      EmployeeName: 'Anders',
      RegisteredHours: getRandomArbitrary(0, 8),
      InvoiceId: 9,
      IsInvoiced: false,
      WorkItemGuid: 9,
      AccountingPeriod: 1,
      IsInClosedAccountingPeriod: true,
    },
  },
];

export const getMoveHours = {
  Entities: generateRows,
  Properties: {
    TotalRegisteredHours: 23.5,
    IsCommentMandatory: true,
    IsShowingProjectNo: true,
    IsShowingTaskNo: true,
    AdditionalCommentFieldName: 'Additional Comment',
  },
  Links: [{ href: 'adjustments/move-hours', rel: 'self' }],
};
