import { Routes, Route } from 'react-router-dom';
import { designSevenPagePath } from 'src/designSevenPagePath';
import { NotFound, FrontPage } from 'src/screens/PageSettings/screens';

export const PageSettingsRouter = () => (
  <Routes>
    <Route path={designSevenPagePath.pageSettings.frontPage} element={<FrontPage />} />
    <Route path={designSevenPagePath.pageSettings.notFound} element={<NotFound />} />
  </Routes>
);
