import { useQuery } from '@tanstack/react-query';
import { getFetch } from 'src/utils/fetcher';
import { IPageSettingsWidgetsResponse, IWidgetRole } from '../types';
import {
  WIDGETS_ROLES_URL_PATH,
  WIDGETS_ROLES_MSW_STATUS_KEY,
  WIDGETS_ROLES_KEY,
  WIDGETS_KEY,
  WIDGETS_KEY_URL_PATH,
  WIDGETS_KEY_STATUS_KEY,
} from '../constants';

export const widgetsRolesFetch = (): Promise<IWidgetRole> =>
  getFetch({ path: WIDGETS_ROLES_URL_PATH, key: WIDGETS_ROLES_KEY });

export const widgetsFetch = (): Promise<IPageSettingsWidgetsResponse> =>
  getFetch({ path: WIDGETS_KEY_URL_PATH, key: WIDGETS_KEY });

export const useGetWidgetsRoles = () => {
  const { data, ...restProps } = useQuery<IWidgetRole>([WIDGETS_ROLES_MSW_STATUS_KEY], () =>
    widgetsRolesFetch(),
  );
  return {
    data: data?.properties,
    ...restProps,
  };
};

export const useGetWidgets = () => {
  const { data, ...restProps } = useQuery<IPageSettingsWidgetsResponse>(
    [WIDGETS_KEY_STATUS_KEY],
    () => widgetsFetch(),
  );
  return {
    data: data?.properties,
    ...restProps,
  };
};
