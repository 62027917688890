import { IconButton } from 'src/components/mui-components';
import { InfoOutlined } from '@mui/icons-material';
import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { translationAnyText } from 'src/utils/translation';
import ReportingWidgetInfoModal from 'src/screens/Home/components/ReportingWidgetInfoModal';
import {
  Typography,
  Stack,
  Card,
  CardHeader,
  Grid,
  Box,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import WidgetWithoutChartShimmer from '../WidgetWithoutChartShimmer';
import WidgetError from '../WidgetError';
import { IWidgetWithoutChart } from '../../HomeType';
import { ValueText } from './components/ValueText';
import styles from './WidgetWithoutChart.module.scss';

export default ({
  queryKey,
  informationList,
  relId,
  isLoading = false,
  isError = false,
  toggleContent,
}: IWidgetWithoutChart) => {
  const { t } = useTranslation('frontpage');
  const [isOpen, setIsOpen] = useState(false);
  const theme = useTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));

  if (isLoading) {
    return <WidgetWithoutChartShimmer />;
  }
  if (isError) {
    return <WidgetError queryKey={queryKey} />;
  }

  return (
    <Card className={`Card-${relId}`} variant="outlined" sx={{ height: '100%' }}>
      <CardHeader
        title={
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Grid container spacing={2}>
              <Grid item sm={12} md={6}>
                <Typography
                  className={styles.widgetTitle}
                  component="h2"
                  data-automation-id={`WidgetHeaderHeading${relId}`}
                >
                  {t(`WidgetTitle${relId}`)}
                </Typography>
              </Grid>
              <Grid item sm={12} md={6}>
                <Box display="flex" justifyContent={isMediumScreen ? 'flex-start' : 'flex-end'}>
                  {toggleContent && toggleContent}
                </Box>
              </Grid>
            </Grid>
          </Stack>
        }
        action={
          <Fragment key={`InfoIconButton${relId}`}>
            <IconButton
              title={t('InfoIconTooltipTitle')}
              size="small"
              aria-label={t('InfoIconTooltipTitle')}
              data-automation-id={`InfoIconButton${relId}`}
              sx={{ paddingTop: toggleContent ? '10px' : 'initial' }}
              onClick={() => setIsOpen(true)}
            >
              <InfoOutlined />
            </IconButton>
            <ReportingWidgetInfoModal
              id={relId}
              title={t(`WidgetTitle${relId}`)}
              infoMessage={t(`WidgetTipsContent${relId}`)}
              openState={isOpen}
              setOpenState={setIsOpen}
            />
          </Fragment>
        }
      />
      <Stack spacing={2} sx={{ px: 2, pb: 2 }}>
        <Stack component="dl" spacing={1}>
          {informationList.map(
            ({ value, trending = null, trendingDirection, key, abbrTitle, abbrText }) => (
              <div className={styles.defWrapper} key={`stack${key}`}>
                {translationAnyText(t, `Widget${relId}${key}Name`) !==
                  `Widget${relId}${key}Name` && (
                  <Typography component="dt" data-automation-id={`Widget${relId}${key}Name`}>
                    {translationAnyText(t, `Widget${relId}${key}Name`)}
                  </Typography>
                )}
                <ValueText
                  value={value}
                  trending={trending}
                  trendingDirection={trendingDirection}
                  key={key}
                  data-automation-id={`Widget${relId}${key}Value`}
                  translationKey={`Widget${relId}${key}Value`}
                  abbrText={abbrText}
                  abbrTitle={abbrTitle}
                />
              </div>
            ),
          )}
        </Stack>
      </Stack>
    </Card>
  );
};
