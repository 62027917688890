import { Box, MenuItem, Select, TextField, Stack } from '@mui/material';
import { TFunction } from 'i18next';
import { SetStateAction } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useGetFrontPageFeature, usePutFeature } from 'src/apis/featureAPI';
import { usePutSetting } from 'src/apis/settingsAPI';

import { ToastifyAlert } from 'src/components/mui-components';
import { useIsPsaProduct } from 'src/utils/siteInfo';
import { SettingsContentWrapper } from 'src/components/layout/SettingsContentWrapper';
import { milestoneDropdownOptions } from 'src/constants/dropdown';
import {} from '@mui/icons-material';
import { SwitchSection, WidgetsRoles } from '../../components';
import { useFeatures } from '../../components/hook/useFeaturesHook';

// Base interface with common props
interface IBaseItems {
  checkedFeatures: Record<string, any>;
  featureValues: Record<string, any>;
  onChange: (value: number | string) => void;
  setFeatureValues: (value: SetStateAction<Record<string, any>>) => void;
  tSysAdminFrontPage?: TFunction<'systemAdminFrontPage', undefined>;
}

// Extended interface for MilestonesDaysSelect
interface IMilestonesItems extends IBaseItems {
  tFrontPage: TFunction<'frontpage', undefined>;
}

const MilestonesDaysSelect = ({
  checkedFeatures,
  featureValues,
  setFeatureValues,
  onChange,
  tSysAdminFrontPage,
  tFrontPage,
}: IMilestonesItems) => (
  <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>
    {tSysAdminFrontPage && tSysAdminFrontPage('MilestonesShowText')}
    <Select
      aria-label="Milestone Days"
      data-automation-id="MilestonesDaysDropdown"
      id="Milestones"
      value={featureValues?.ShowMileStones !== undefined ? featureValues.ShowMileStones : ''}
      variant="outlined"
      size="small"
      disabled={!checkedFeatures?.ShowMileStones}
      onChange={(e) => {
        setFeatureValues({ ...featureValues, ShowMileStones: e.target.value });
        onChange(e.target.value);
      }}
      sx={{ ml: 1, mr: 1 }}
    >
      {Object.entries(milestoneDropdownOptions).map(([key, value]) => (
        <MenuItem key={key} value={key}>
          {tFrontPage ? tFrontPage(value) : value}
        </MenuItem>
      ))}
    </Select>
  </Box>
);

const TimeTrackingDelayCompanyTargetInput = ({
  checkedFeatures,
  featureValues,
  setFeatureValues,
  onChange,
  tSysAdminFrontPage,
}: IBaseItems) => (
  <Stack direction="row" alignItems="center" gap={2}>
    {tSysAdminFrontPage && tSysAdminFrontPage('CompanyTargetInDaysText')}
    <TextField
      hiddenLabel
      aria-label="Company Target"
      data-automation-id="CompanyTargetInput"
      id="CompanyTarget"
      value={
        featureValues?.WidgetTimeTrackingDelay !== undefined
          ? featureValues.WidgetTimeTrackingDelay
          : ''
      }
      variant="outlined"
      size="small"
      disabled={!checkedFeatures?.WidgetTimeTrackingDelay}
      onChange={(e) => {
        if (e.target.value === '' || Number.isInteger(Number(e.target.value))) {
          setFeatureValues({
            ...featureValues,
            WidgetTimeTrackingDelay: e.target.value === '' ? '' : Number(e.target.value),
          });
        }
      }}
      onBlur={(e) => {
        if (e.target.value === '') {
          setFeatureValues({
            ...featureValues,
            WidgetTimeTrackingDelay: 1, // Default value
          });
          onChange(1);
        } else {
          onChange(e.target.value);
        }
      }}
      sx={{ width: 60 }}
    />
  </Stack>
);

export const FrontPage = () => {
  const { data } = useGetFrontPageFeature();
  const { mutate: updateFeature } = usePutFeature();
  const { mutate: updateSetting } = usePutSetting();
  const isPsaProduct = useIsPsaProduct();

  const { t: tSysAdminFrontPage } = useTranslation('systemAdminFrontPage');
  const { t: tFrontPage } = useTranslation('frontpage');
  const { t: tNotification } = useTranslation('notificationCenter');
  const { t: tWidget } = useTranslation('reportingWidgets');

  const { checkedFeatures, setCheckedFeatures, featureValues, setFeatureValues } =
    useFeatures(data);

  // Toggle feature with checkbox
  const toggleCheckbox = async (name: string, isChecked: boolean, label: string) => {
    const feature = data.find((feat: { name: string }) => feat.name === name);

    if (!feature) return;

    const response = await updateFeature(
      {
        featureId: feature.identifier,
        featureEnable: isChecked,
      },
      {
        onSuccess: () => {
          const toastText = isChecked
            ? tSysAdminFrontPage('FeatureEnabled', {
                NAME: label,
              })
            : tSysAdminFrontPage('FeatureDisabled', {
                NAME: label,
              });
          toast.success(<ToastifyAlert description={toastText} />, {
            autoClose: 2000,
            closeButton: false,
          });
        },
      },
    );

    if (response !== undefined && response !== null) {
      setCheckedFeatures((prev) => ({ ...prev, [name]: isChecked }));
    }
  };

  const onChangeMilestones = (value: number | string) => {
    updateSetting(
      { identifier: 'FrontPageMilestonePeriod', value: Number(value) },
      {
        onSuccess: () => {
          toast.success(
            <ToastifyAlert description={tSysAdminFrontPage('MilestonesUpdateSuccessMessage')} />,
            {
              autoClose: 2000,
              closeButton: false,
            },
          );
        },
      },
    );
  };

  const onChangeCompanyTarget = (value: number | string) => {
    updateSetting(
      { identifier: 'FrontPageCompanyTarget', value: Number(value) },
      {
        onSuccess: () => {
          toast.success(
            <ToastifyAlert description={tSysAdminFrontPage('CompanyTargetSuccessMessage')} />,
            {
              autoClose: 2000,
              closeButton: false,
            },
          );
        },
      },
    );
  };

  const sections = [
    {
      title: tSysAdminFrontPage('SidebarSectionName'),
      items: [
        {
          name: 'FrontPageInternalMessage',
          label: tFrontPage('InternalInformationHeading'),
          tooltipTitle: tFrontPage('InternalInformationHeading'),
          tooltipDesc: tFrontPage('InternalInformationText'),
          isVisible: isPsaProduct,
        },
        {
          name: 'DesignSevenNotificationCenter',
          label: tNotification('Heading'),
          tooltipTitle: tNotification('InfoModalHeading'),
          tooltipDesc: tNotification('InfoModalText'),
          isVisible: isPsaProduct,
        },
        {
          name: 'ShowMileStones',
          label: tSysAdminFrontPage('MilestonesInInfoboxName'),
          tooltipTitle: tSysAdminFrontPage('MilestonesName'),
          tooltipDesc: tSysAdminFrontPage('MilestonesText'),
          configs: (
            <MilestonesDaysSelect
              checkedFeatures={checkedFeatures}
              featureValues={featureValues}
              onChange={onChangeMilestones}
              setFeatureValues={setFeatureValues}
              tSysAdminFrontPage={tSysAdminFrontPage}
              tFrontPage={tFrontPage}
            />
          ),
          isVisible: true,
        },
      ],
    },
    {
      title: tSysAdminFrontPage('WidgetsName'),
      items: [
        {
          name: 'WidgetTimeTrackingDelay',
          label: tWidget('TimeTrackingDelay'),
          tooltipTitle: tWidget('TimeTrackingDelay'),
          tooltipDesc: (
            <Trans
              i18nKey="AddedValueBarPerMonthWidgetDescription"
              key="AddedValueBarPerMonthWidgetDescription"
              defaults={tSysAdminFrontPage('TimeTrackingDelayDescription')}
              components={{ 1: <br /> }}
            />
          ),
          configs: (
            <TimeTrackingDelayCompanyTargetInput
              checkedFeatures={checkedFeatures}
              featureValues={featureValues}
              onChange={onChangeCompanyTarget}
              setFeatureValues={setFeatureValues}
              tSysAdminFrontPage={tSysAdminFrontPage}
            />
          ),
          isVisible:
            'WidgetTimeTrackingDelay' in checkedFeatures && checkedFeatures.WidgetTimeTrackingDelay,
        },
      ],
    },
  ];

  return (
    <>
      <SettingsContentWrapper
        header={tSysAdminFrontPage('Heading')}
        description={tSysAdminFrontPage('Description')}
      >
        <>
          {sections.map((section) => (
            <SwitchSection
              key={section.title}
              title={section.title}
              items={section.items}
              checkedItems={checkedFeatures}
              onToggle={toggleCheckbox}
              translation="systemAdminFrontPage"
            />
          ))}
        </>
      </SettingsContentWrapper>
      <SettingsContentWrapper
        header={tSysAdminFrontPage('WidgetsName')}
        description={tSysAdminFrontPage('WidgetsAndRolesDescription')}
      >
        <WidgetsRoles />
      </SettingsContentWrapper>
    </>
  );
};
