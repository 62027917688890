// pageSettingsMock.ts

const widgetsResult = {
  Properties: {
    WidgetList: [
      {
        WidgetID: 1160,
        WidgetName: 'WidgetHourKPIHoursOnly',
      },
      {
        WidgetID: 1158,
        WidgetName: 'WidgetHourKPI',
      },
      {
        WidgetID: 1159,
        WidgetName: 'WidgetHourKPIWithBAR',
      },
      {
        WidgetID: 1152,
        WidgetName: 'WidgetInvoicedPerMonth',
      },
      {
        WidgetID: 1155,
        WidgetName: 'WidgetAverageHourlyRate',
      },
      {
        WidgetID: 1151,
        WidgetName: 'WidgetPlannedAbsence',
      },
      {
        WidgetID: 1154,
        WidgetName: 'WidgetProjectEconomyHealth',
      },
      {
        WidgetID: 1156,
        WidgetName: 'WidgetTimeTrackingDelay',
      },
      {
        WidgetID: 1157,
        WidgetName: 'WidgetVacationCalculation',
      },
      {
        WidgetID: 1161,
        WidgetName: 'WidgetBillablePercentage',
      },
      {
        WidgetID: 1162,
        WidgetName: 'WidgetExternalPercentage',
      },
    ],
  },
  Links: [
    {
      Href: 'http://localhost/tlp/api/v2/page-settings/widgets',
      Rel: 'self',
    },
  ],
};

export { widgetsResult };
