import {
  useGetExternalPercentageForHomeWidget,
  GET_EXTERNAL_PERCENTAGE_KEY,
} from 'src/apis/externalPercentageAPI';
import WidgetWithoutChart from '../WidgetWithoutChart';

export const ExternalPercentageWidget = () => {
  const {
    isError: isExternalPercentageError,
    isLoading: isExternalPercentageLoading,
    informationList: externalPercentageInformationList,
  } = useGetExternalPercentageForHomeWidget();

  return (
    <WidgetWithoutChart
      queryKey={GET_EXTERNAL_PERCENTAGE_KEY}
      relId="ExternalPercentage"
      informationList={externalPercentageInformationList}
      isError={isExternalPercentageError}
      isLoading={isExternalPercentageLoading}
    />
  );
};
