export const WIDGETS_ROLES_URL_PATH = '/api/v2/role-widgets';
export const WIDGETS_ROLES_MSW_STATUS_KEY = 'widgetsRolesAPI';
export const WIDGETS_ROLES_KEY = 'WIDGETS_ROLES';
export const WIDGETS_UPDATE_ROLE_URL_PATH = '/api/v2/role-widgets';
export const WIDGETS_UPDATE_ROLE = 'WIDGETS_UPDATE_ROLE';
export const WIDGETS_KEY_URL_PATH = '/api/v2/page-settings/widgets';
export const WIDGETS_KEY = 'widgetsAPI';
export const WIDGETS_KEY_STATUS_KEY = 'WIDGETS';
export const WIDGETS_UPDATE_ORDER_URL_PATH = '/api/v2/page-settings/widgets/displayorder';
export const WIDGETS_UPDATE_ORDER = 'widgetsUpdateKey';
