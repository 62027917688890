import { useMutation } from '@tanstack/react-query';
import { patchFetch } from 'src/utils/fetcher';
import { IUpdateWidgetOrderParams, IUpdateWidgetParams } from '../types';
import {
  WIDGETS_UPDATE_ROLE_URL_PATH,
  WIDGETS_UPDATE_ROLE,
  WIDGETS_UPDATE_ORDER_URL_PATH,
  WIDGETS_UPDATE_ORDER,
} from '../constants';

const updateWidgetRole = ({ widgetId, roleId, enabled }: IUpdateWidgetParams) =>
  patchFetch({
    path: `${WIDGETS_UPDATE_ROLE_URL_PATH}`,
    key: WIDGETS_UPDATE_ROLE,
    body: JSON.stringify({
      widgetId,
      roleId,
      enabled,
    }),
  });

export const usePutWidgetRole = () => useMutation([WIDGETS_UPDATE_ROLE], updateWidgetRole);

const updateWidgetOrder = ({ widgetId, displayOrder }: IUpdateWidgetOrderParams) =>
  patchFetch({
    path: `${WIDGETS_UPDATE_ORDER_URL_PATH}`,
    key: WIDGETS_UPDATE_ORDER,
    body: JSON.stringify({
      widgetId,
      displayOrder,
    }),
  });

export const usePutWidgetOrder = () => useMutation([WIDGETS_UPDATE_ORDER], updateWidgetOrder);
